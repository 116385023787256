<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"queryItems",

  watch: {
    items() {
      this.$store.commit('graphqlData', { item: 'items', result: this.items });
     },
  },

  setup() {
    const store = useStore();

    const variables = reactive({
      slug: computed(() => store.state.data.categorySlug),
      channel: computed(() => store.state.data.channel),
    });

    useClient({
      url: process.env.VUE_APP_SALEOR_URL
    });

    const queryItems = `
      query ($channel: String!, $slug: String!){
        products(first: 100, channel: $channel, filter: { metadata: {key: "category", value: $slug} }) {
          edges {
            node {
              slug
              name
              variants {
                id
                name
                attributes(variantSelection: ALL){
                  attribute{
                    id
                    name
                  }
                  values{
                    id
                    name
                  }
                }
              }
              pricing {
                priceRange {
                  start {
                    net {
                      amount
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const { data: items } = useQuery({
      query: queryItems,
      variables
    });

    return { items };
  },
}
</script>
