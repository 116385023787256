<template>
  <div>
  <div id="cat" class="font-stolzl min-h-screen">

    <!-- SPACE-->
    <div id="space" class="md:h-16"></div>
    <!-- SPACE-->

    <!-- HEADING-->
    <div  class="w-auto h-auto overflow-hidden">
      <div v-if="currentCategory?.category?.name" :ref="setRef" data-anim="0" class="font-light text-coolGray-900 mt-5 md:mt-28 mb-5 text-5xl px-4 md:px-8 opacity-0">{{currentCategory.category.name}}</div>
    </div>
    <!-- HEADING-->

    <!-- ITEMS-->
    <div v-if="categoryItems?.items" class="grid grid-cols-1 md:grid-cols-2 gap-8 px-4 md:px-8 mt-2 min-h-screen mb-10">
      <div v-for="(item,index) in categoryItems.items" :key=item :ref="setRef" data-anim="0" class="h-66 w-full cursor-pointer opacity-0">
        <router-link :to="{ name: 'Products', params: { productId: item.id, slug: item.slug } }">
          <div class="relative bg-coolGray-100 h-full w-full flex flex-row justify-center items-center group overflow-hidden">
            <div v-if="categoryImages?.items[index]?.images[0]" class="transition duration-300 ease-in-out w-5/6 md:w-4/6 h-full transform-gpu md:group-hover:scale-105 bg-local bg-center bg-no-repeat bg-contain md:bg-contain" :style="{ backgroundImage: 'url(' + categoryImages.items[index].images[0].url + ')' }"></div>
            <div v-if="item?.tags?.length > 0" class="absolute top-0  mt-4 md:mt-8 pl-5 md:pl-10 w-full flex flex-row flex-wrap justify-start items-center">
              <div v-for="tag in item.tags" :key=tag>
                <div class="bg-coolGray-900 text-coolGray-50 mr-1 mb-1 rounded-lg text-xs font-normal px-2 py-1 tracking-widest">{{formatTag(tag)}}</div>
              </div>
            </div>
            <div class="w-full h-auto overflow-hidden  bottom-0 absolute mb-5">
            <div :ref="setRef" data-anim="0" class=" opacity-0 w-full flex flex-col md:flex-row justify-start md:justify-between items-start md:items-end px-4 md:px-8">
              <div>

                <div v-if="items?.products">
                  <div v-for="(product) in items.products.edges" :key=product>
                    <div v-if="item.slug == product.node.slug">
                      <div class="flex flex-row justify-start items-center">
                        <div v-for="color in colors(product)" :key=color class="mr-3 mb-2">
                          <div class="transition duration-300 ease-out w-6 h-6 rounded-full" :style="{ backgroundColor: colorCodes[color] }"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="font-medium text-lg text-coolGray-900">{{item.name}}</div>
                <div class="text-sm text-coolGray-700 font-light">{{item.subtext}}</div>
              </div>
              <div v-if="items?.products">
                <div v-for="(product) in items.products.edges" :key=product>
                  <div v-if="item.slug == product.node.slug">
                    <div v-if="product.node.slug == item.slug" class="font-light text-coolGray-700 md:text-coolGray-900 text-sm md:text-lg">ab {{formatPrice(product.node.pricing.priceRange.start.net.amount)}}</div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <!-- ITEMS-->

  </div>

  <!-- COMPONENTS-->
  <queryCategoryImages />
  <queryCategoryItems />
  <getCurrentCategoryQuery />
  <queryItems />
  <Footer />
  </div>
</template>

<script>
import Footer from '../components/footer.vue';
import queryCategoryItems from '../graphql/graphcms/queryCategoryItems.vue';
import queryCategoryImages from '../graphql/graphcms/queryCategoryImages.vue';
import getCurrentCategoryQuery from '../graphql/graphcms/getCurrentCategoryQuery.vue';
import queryItems from '../graphql/saleor/queryItems.vue';

import formatPrice from '../mixins/formatPrice.vue';
import intersectionObserver from '../mixins/intersectionObserver.vue';

export default {
  name: 'categories',
  props: ['categoryId', 'slug'],
  components: {
    Footer, queryCategoryItems, queryCategoryImages, queryItems, getCurrentCategoryQuery,
  },
  mixins: [formatPrice, intersectionObserver],

  computed: {
    // graphql
    seo() {
      return this.$store.state.data.seo;
    },
    categoryImages() {
      return this.$store.state.data.categoryImages;
    },
    categoryItems() {
      return this.$store.state.data.categoryItems;
    },
    items() {
      console.log('items', this.$store.state.data.items);
      return this.$store.state.data.items;
    },
    currentCategory() {
      return this.$store.state.data.currentCategory;
    },
    // data
    text() {
      return this.$store.state.lang.categories[this.$store.state.lang.currentLang];
    },
    category() {
      return this.$store.state.data.categorySlug;
    },
    colorCodes() {
      return this.$store.state.data.colorCodes;
    },
  },

  watch: {
    seo() {
      document.title = this.seo?.metaTitle;
      document.querySelector('meta[name="description"]').setAttribute('content', this.seo?.metaDescription);
    },
    $route(to, from) {
      if (to.name === from.name) {
        this.$store.commit('setCategory', to.params.slug);
      }
    },
  },

  mounted() {
    this.$store.commit('setCategory', this.slug);
    this.$store.commit('setPosition', 'other');

    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 250);
  },

  methods: {

    formatTag(tag) {
      return tag.replace(/_/g, ' ').toUpperCase();
    },

    colors(product) {
      const colors = [];
      if (product) {
        for (let i = 0; i < product.node.variants.length; i += 1) {
          for (let ii = 0; ii < product.node.variants[i].attributes.length; ii += 1) {
            if (product.node.variants[i].attributes[ii].attribute.name === 'color') {
              if (product.node.variants[i].attributes[ii].values.length > 0) {
                const value = product.node.variants[i].attributes[ii].values[0].name;
                if (!colors.includes(value)) {
                  colors.push(value);
                }
              }
            }
          }
        }
      }
      return colors;
    },

  },

};
</script>
